import React from "react"
import {
  Title,
  Subtitle,
  Section,
  Container,
  Columns,
  Column,
  Button,
  Box,
} from "bloomer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Interview from "../assets/interview.svg"
import { Link } from "gatsby"

const Careers = () => (
  <Layout>
    <SEO title="Careers" />
    <Section>
      <Container>
        <Columns>
          <Column className="job-text-box" isSize="1/2">
            <Title>Careers</Title>
            <Box hasTextAlign="left">
              <p>
                Thanks for your interest!<br/>Unfortunately, we aren't hiring at the moment.
                 <br /> Please feel free to {" "}
                <Link to="/contact">contact us</Link>
              </p>
            </Box>
            <Link to="/">
              <Button isSize="large" isColor="primary">
                Home
              </Button>
            </Link>
          </Column>
          <Column isSize="1/2">
            <Interview width="vw" height="400px" />
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export default Careers
